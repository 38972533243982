import React, { useState, useEffect } from "react";

export default function AboutUs() {
  return (
    <div className="mx-auto max-w-6xl p-2">
      <div className="w-full text-gray-600 p-2 text-xl text-center border-b border-gray-300 tracking-wider">
        About KSTAT
      </div>
      <div className="w-full p-4 flex flex-col gap-4">
        <h2 className="text-primary text-lg font-semibold">Why:</h2>
        <p>
          Our motto at SarStuff is to “Help you help others” so with this in mind
          we have designed an information source to help UK Police and Search &
          Rescue teams find vulnerable missing people by using historic data
          taken from other missing person searches. The goal of this system is
          to enable anyone across the UK to get localised statistics pertaining
          to missing people.
        </p>
        <h2 className="text-primary text-lg font-semibold">How:</h2>
        <p>
          While not the same, the behaviours of vulnerable missing people are
          often repeated and so by using the statistics on previous find
          locations it may assist with the planning of a new search or reduce
          the time taken to locate someone. You can enhance the criteria by
          including the gender and time the person went missing. No information
          entered or held here is privileged information and all stats entered
          onto the system are vetted and checked by experienced qualified Search
          and Rescue personnel.{" "}
        </p>
        <h2 className="text-primary text-lg font-semibold">What:</h2>
        <p>
          KSTAT is one of the systems created by SarStuff to enhance and improve
          the art of search management whether it is for a Police Team or Search
          and Rescue organisation. For more information about our systems and
          other technologies please visit{" "}
          <a
            className="text-primary"
            href="https://sarstuff.co.uk"
            target="_blank"
          >
            sarstuff.co.uk
          </a>
        </p>

        <p className="text-primary text-xl font-bold text-center">
          KSTAT is free to use now and forever, it always has been and always
          will be.
        </p>
        <h2 className="text-primary text-lg font-semibold">You can help to:</h2>
        <p>
          KSTAT is designed to be a live system, we need you to help yourself
          and other organisations around the UK by sharing your missing person
          data with us. Please contact us via our{" "}
          <a
            className="text-primary"
            href="https://sarstuff.co.uk"
            target="_blank"
          >
            website
          </a>{" "}
          or{" "}
          <a
            href="https://www.facebook.com/sarstuff.co.uk"
            target="_blank"
            className="text-primary"
          >
            facebook page
          </a>{" "}
          to learn how you can feed your verified statistics into the system.
        </p>
      </div>
    </div>
  );
}
