class FetchRequest {
  getData = async (url = "") => {
    const response = await fetch(url, {
      method: "GET",
    });
    return response.json();
  };
  getTextData = async (url = "") => {
    const response = await fetch(url, {
      method: "GET",
    });
    return response.text();
  };
  postData = async (url = "", data = {}) => {
    const response = await fetch(url, {
      method: "POST",

      body: JSON.stringify(data),
    });
    return response.json();
  };
  postFormData = async (url = "", data) => {
    const response = await fetch(url, {
      method: "POST",

      body: data,
    });
    return response.json();
  };
  patchData = async (url = "", data = {}) => {
    const response = await fetch(url, {
      method: "PATCH",

      body: JSON.stringify(data),
    });
    return response.json();
  };
  async get(url) {
    const res = await this.getData(url);
    if (res.statusCode === 404) {
      return [];
    } else {
      return res;
    }
  }
  async getText(url) {
    const res = await this.getTextData(url);
    if (res.statusCode === 404) {
      return [];
    } else {
      return res;
    }
  }
  async post(url, data) {
    const res = await this.postData(url, data);
    return res;
  }
  async patch(url, data) {
    const res = await this.patchData(url, data);
    return res;
  }
}

export default new FetchRequest();
