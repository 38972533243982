import React from "react";
import ReactGA from "react-ga4";
import AppConfig from "./_appConfig";
import BuildNumber from "./_buildNumber";

export default class GoogleAnalytics extends React.Component {
    heartbeat = null;

    componentDidMount(){
        if (AppConfig.isDebug) {
          return;
        }

        if(this.props.trackingID === ""){
            return;
        }

        ReactGA.initialize(this.props.trackingID, {debug: AppConfig.isDebug});
        this.sendPageView(this.props.location);
        ReactGA.event({
            category: "app_build",
            action: "Build: #" + BuildNumber,
            nonInteraction: true,
        });
        this.props.history.listen(this.sendPageView);

        this.sendHeartbeat();
        this.heartbeat = setInterval(() => this.sendHeartbeat(), 60 * 1000);
    }
    componentWillUnmount(){
        if (this.heartbeat !== null){
            clearInterval(this.heartbeat);
            this.heartbeat = null;
        }
    }
    sendPageView(location){
        const page = location.pathname;
        ReactGA.send({hitType: "pageview", page, title: "Route: " + page});
        if (AppConfig.isDebug){
            console.info(`[react-router-ga] Page view: ${page}`);
        }
    }

    sendHeartbeat(){
        ReactGA.event({
            category: "background_events",
            action: "Heartbeat",
            nonInteraction: true,
        });
    }

    render(){
        return this.props.children;
    }
}
