import React, { Suspense, lazy } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import logo from "./assets/images/logo.png";
import AppConfig from "./_appConfig";
import HomePage from "./pages/home";
import MisperTypePage from "./pages/misperType";
import AboutUs from "./pages/about";
import GoogleAnalytics from "./GoogleAnalytics";

const Header = lazy(() => import("./components/ui/Header"));
const Footer = lazy(() => import("./components/ui/Footer"));

function App({location, history}){
  return (
    <Suspense fallback={<div>Loading</div>}>
      <GoogleAnalytics
          trackingID={AppConfig.googleAnalyticsTag}
          location={location}
          history={history}
      >
        <Toaster position={"topright"} />
        <div className="flex flex-col justify-between min-h-screen">
        <div>
          <Header
            title={
              <img
                src={logo}
                alt="kstat logo"
                className="w-32 mx-auto aspect-auto"
              />
            }
          />

          <Switch>
            <Route exact path={`/`} component={HomePage} />
            <Route exact path={`/about`} component={AboutUs} />
            <Route
              exact
              path={`/misper-type/:type`}
              component={MisperTypePage}
            />
          </Switch>
        </div>
        <Footer />
      </div>
      </GoogleAnalytics>
    </Suspense>
  );
}

export default withRouter(App);
