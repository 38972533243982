import React, { useState, useEffect, useRef, useCallback, lazy } from "react";
import { useParams } from "react-router-dom";
import api from "../api";

import Geosuggest from "react-geosuggest";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import UseWindowDimensions from "../components/UseWindowDimensions";
import { ArrowsExpandIcon, LocationMarkerIcon } from "@heroicons/react/outline";
import { GlobeAltIcon, PrinterIcon } from "@heroicons/react/solid";
import toast from "react-hot-toast";

const Select = lazy(() => import("../components/ui/Select"));
const StatTable = lazy(() => import("../components/ui/StatTable"));
const MainMap = lazy(() => import("../components/mapping/MainMap"));

const gender = [
  { key: 1, text: "Unknown", value: "" },
  { key: 2, text: "Male", value: "Male" },
  { key: 3, text: "Female", value: "Female" },
];
const tod = [
  { key: 1, text: "Unknown", value: "" },
  { key: 2, text: "Night", value: "night" },
  { key: 3, text: "Day", value: "day" },
];

export default function MisperTypePage() {
  let params = useParams(":type");
  const { height, width } = UseWindowDimensions();
  const _geoSuggest = useRef(null);
  const [filter, setFilter] = useState({
    region: "",
    gender: "",
    tod: "",
  });
  const [data, setData] = useState({
    type: "--",
    searches: "0",
    distance: {},
    trackOffset: {},
    conditions: [],
    locations: [],
  });
  // const [theGender, setGender] = useState("");
  const [location, setLocation] = useState({
    type: "Feature",
    geometry: {
      type: "Point",
      coordinates: [0.89268, 51.22715],
    },
  });
  const [isFull, setIsFull] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [printMap, setPrintMap] = useState(false);
  const [changeMap, setChangeMap] = useState(1);
  const [counties, setCounties] = useState([]);
  const [mapData, setMapData] = useState({
    distance: [],
    locations: [],
  });

  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    getData();
  }, [filter]);

  const handle = useFullScreenHandle();

  const reportChange = useCallback(
    (state, handle) => {
      if (handle === true) {
        setIsFull(true);
      } else {
        setIsFull(false);
      }
    },
    [handle]
  );

  const getData = () => {
    let query =
      "?county=" +
      encodeURI(filter.region).replace(/&/g, "%26") +
      "&gender=" +
      filter.gender +
      "&tod=" +
      filter.tod;
    api.stats.getData(params.type, query).then((res) => {
      // console.log(res);
      setData(res);
      setMapData({
        distance: [
          {
            label: res.distance.d25 + "km  25",
            value: res.distance.d25,
            color: "red",
          },
          { label: res.distance.d50 + "km  50", value: res.distance.d50 },
          { label: "75", value: res.distance.d75, color: "green" },
          { label: "95", value: res.distance.d95, color: "purple" },
        ],
        locations: res.locations,
      });
    });
    api.stats.getCounties().then((res) => {
      setCounties([
        {
          key: -1,
          text: "***no county***",
          value: "",
        },
        ...res,
      ]);
    });
  };
  const reset = () => {
    setFilter({
      gender: "",
      region: "",
      tod: "",
    });
  };
  const onSuggestSelect = (suggest) => {
    // console.log(suggest);
    if (suggest) {
      setLocation({
        ...location,
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [
            parseFloat(suggest.location.lng).toFixed(4),
            parseFloat(suggest.location.lat).toFixed(4),
          ],
        },
        properties: {
          address: suggest.gmaps.formatted_address,
        },
      });
    }
  };
  const addipp = (data) => {
    if (data && data.length) {
      setLocation({
        ...location,
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: data,
        },
        properties: {
          // ...this.state.data.missingFrom.properties
        },
      });
    }
  };
  const getLoc = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(success, error, {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      });
      toast.success("Getting location");
    } else {
      setErrorMessage("Loc not available");
      toast.error("Loc not available");
    }
  };
  const error = async (err) => {
    setErrorMessage(err.message);
    toast.error(err.message);
  };
  const success = async (position) => {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;

    setLocation({
      ...location,
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [longitude.toFixed(4), latitude.toFixed(4)],
      },
      properties: {
        // ...this.state.data.missingFrom.properties
      },
    });
    toast.success("Location found");
    setErrorMessage("");
  };
  // console.log(height, isFull, handle.active);
  return (
    <div className="mx-auto max-w-7xl flex flex-col gap-4 p-2">
      <div className="text-2xl px-2 tracking-wider">
        Misper Type:{" "}
        <span className="text-primary font-semibold">{data.type}</span> from{" "}
        <span className="text-primary font-semibold">#{data.searches}</span>{" "}
        searches
      </div>
      <div className="filterBar p-2 border border-primary rounded flex flex-col sm:flex-row gap-6">
        <div className="flex  gap-2 flex-1 items-end">
          <Select
            label="Gender"
            onChange={(e) => setFilter({ ...filter, gender: e.target.value })}
            options={gender}
            value={filter.gender}
          />
          <Select
            label="Time of day went missing"
            onChange={(e) => setFilter({ ...filter, tod: e.target.value })}
            options={tod}
            value={filter.tod}
          />
          <button
            className="p-1 bg-gray-200 rounded-md text-sm h-10 border border-gray-300 shadow-md"
            onClick={reset}
          >
            Reset
          </button>
        </div>
        <div className="flex flex-col gap-2 flex-1">
          <label className="text-primary">
            Type the address in here or click on map
          </label>
          <Geosuggest
            autoComplete="off"
            ref={_geoSuggest}
            placeholder="4 the street, Ashford Kent"
            inputClassName="geosuggest__input bg-gray-100 rounded p-2 w-full border border-gray-300"
            onSuggestSelect={onSuggestSelect}
            suggestsClassName="geosuggest__suggests absolute bg-gray-100 text-gray-800 z-50"
            suggestsHiddenClassName="geosuggest__suggests--hidden hidden"
            suggestItemClassName="p-2 border-b border-gray-300 cursor-pointer hover:bg-gray-200"
          />
        </div>
      </div>
      <div className={`grid grid-cols-1 sm:grid-cols-2 gap-4`}>
        <div className="col-span-1">
          <div className={`grid grid-cols-1 sm:grid-cols-2 gap-4`}>
            <div className="statTable col-span-1  rounded-t ring ring-primary">
              <div className="bg-primary text-white text-center text-lg p-2 w-full ">
                Distance
              </div>
              <table className="min-w-full divide-y divide-gray-300 ">
                <thead className="bg-gray-100 divide-y divide-gray-200 ">
                  <tr className="divide-x divide-gray-200">
                    <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      %
                    </th>
                    <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      km
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white ">
                  <tr className="divide-x divide-gray-200">
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 bg-gray-50">
                      25%
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 bg-gray-50">
                      {data.distance.d25}
                    </td>
                  </tr>
                  <tr className="divide-x divide-gray-200">
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 bg-gray-50">
                      50%
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 bg-gray-50">
                      {data.distance.d50}
                    </td>
                  </tr>
                  <tr className="divide-x divide-gray-200">
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 bg-gray-50">
                      75%
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 bg-gray-50">
                      {data.distance.d75}
                    </td>
                  </tr>
                  <tr className="divide-x divide-gray-200">
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 bg-gray-50">
                      95%
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {data.distance.d95}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="statTable col-span-1  rounded-t ring ring-primary">
              <div className="bg-primary text-white text-center text-lg p-2 w-full ">
                Track Offset
              </div>
              <table className="min-w-full divide-y divide-gray-300 ">
                <thead className="bg-gray-100 divide-y divide-gray-200 ">
                  <tr className="divide-x divide-gray-200">
                    <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      %
                    </th>
                    <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      m
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white ">
                  <tr className="divide-x divide-gray-200">
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 bg-gray-50">
                      25%
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 bg-gray-50">
                      {data.trackOffset.t25}
                    </td>
                  </tr>
                  <tr className="divide-x divide-gray-200">
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 bg-gray-50">
                      50%
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 bg-gray-50">
                      {data.trackOffset.t50}
                    </td>
                  </tr>
                  <tr className="divide-x divide-gray-200">
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 bg-gray-50">
                      75%
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 bg-gray-50">
                      {data.trackOffset.t75}
                    </td>
                  </tr>
                  <tr className="divide-x divide-gray-200">
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 bg-gray-50">
                      95%
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {data.trackOffset.t95}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className={`grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4 `}>
            <div className="statTable col-span-1   ">
              <StatTable title="Condition" data={data.conditions} />
            </div>
            <div className="statTable col-span-1 ">
              <StatTable title="Locations" data={data.locations} />
            </div>
          </div>
        </div>
        <div className="col-span-1 ">
          <div className="border-2 border-primary  rounded-t">
            <div className="bg-primary text-white p-2 ">
              <div className="flex">
                <button
                  aria-label="change map"
                  onClick={() => setChangeMap(changeMap + 1)}
                  className="p-2 flex-1 "
                >
                  <GlobeAltIcon className="h-6 mx-auto hover:text-gray-600" />
                </button>
                <button
                  aria-label="print map"
                  onClick={() => {
                    setPrintMap(true);
                    setTimeout(() => {
                      setPrintMap(false);
                    }, 500);
                  }}
                  className="p-2 flex-1 "
                >
                  <PrinterIcon className="h-6 mx-auto hover:text-gray-600" />
                </button>
                <button
                  aria-label="show your location"
                  onClick={getLoc}
                  className="p-2 flex-1  hover:bg-opacity-40"
                >
                  <LocationMarkerIcon className="h-6 mx-auto hover:text-gray-600" />
                </button>
                <button
                  aria-label="go fullscreen"
                  onClick={handle.enter}
                  className="p-2 flex-1 "
                >
                  <ArrowsExpandIcon className="h-6 mx-auto hover:text-gray-600" />
                </button>
              </div>
            </div>
            <FullScreen handle={handle} onChange={reportChange}>
              <MainMap
                ipp={addipp}
                ippLocation={location}
                statData={mapData}
                changeMap={changeMap}
                height={handle.active ? height : height - 320}
                isFull={handle.active}
                print={printMap}
                handle={handle.exit}
              />
            </FullScreen>
          </div>
        </div>
      </div>
    </div>
  );
}
