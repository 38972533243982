import React, { useState, useEffect } from "react";

import api from "../api";

export default function Home({ history }) {
  const [misperTypes, setMisperTypes] = useState({
    types: [],
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    api.stats.getSearchTypes().then((res) => {
      setMisperTypes(res);
    });
  };
  // console.log({ misperTypes });
  return (
    <div className="mx-auto max-w-6xl ">
      <div className="w-full text-center p-4 flex flex-col gap-4">
        <p className="text-primary text-lg font-bold ">
          KSTAT is free to use now and forever, it always has been and always
          will be.
        </p>
        <p className="text-center">
          This system is designed to help UK SaR/Police teams find vulnerable
          missing people. No information entered or held here is privileged
          information.
        </p>
      </div>
      <div className="w-full sm:p-4">
        <div className="border border-gray-200 rounded-lg pb-4">
          <div className="text-center text-lg text-primary font-semibold py-2">
            Published Searches by Misper Types (#{misperTypes.published})
          </div>
          <div className="w-full p-4 flex flex-wrap gap-4">
            {misperTypes.types.map((t, i) => {
              let colour =
                "bg-red-300 text-red-700 shadow-red-400 hover:shadow-red-500";
              if (t.count > 200) {
                colour =
                  "bg-green-300 text-green-700 shadow-green-400 hover:shadow-green-500";
              }
              if (t.count > 100 && t.count < 200) {
                colour =
                  "bg-yellow-300 text-yellow-700 shadow-yellow-400  hover:shadow-yellow-500";
              }

              return (
                <div
                  onClick={() => history.push(`/misper-type/${t._id}`)}
                  key={i}
                  className={`rounded-lg p-4 ${colour} bg-opacity-50 shadow-lg cursor-pointer hover:bg-opacity-70 flex-grow flex-shrink`}
                >
                  {t._id} <span className="font-bold ">#{t.count}</span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
