import fetchRequest from "./fetchRequest";
import AppConfig from "../_appConfig";
const server = AppConfig.api_server;

const api = {
  stats: {
    getSearchTypes: () =>
      fetchRequest.get(server + `/public/searchtypes`).then((res) => res),
    getData: (id, query) =>
      fetchRequest
        .get(server + `/public/stat/${id}/${query}`)
        .then((res) => res),
    getMisperTypes: () =>
      fetchRequest.get(server + `/public/mispertypes`).then((res) => res),
    getCounties: () =>
      fetchRequest.get(server + `/public/counties`).then((res) => res),
    getLocationTypes: () =>
      fetchRequest.get(server + `/public/findlocations`).then((res) => res),
    getMissingFrom: () =>
      fetchRequest.get(server + `/public/missingfrom`).then((res) => res),
    getAreaType: () =>
      fetchRequest.get(server + `/public/areatypes`).then((res) => res),
  },
};
export default api;
